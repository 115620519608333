.full-screen-loading-content {
    position: fixed;
    z-index: 1000;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 200px;
    height: 300px;
}

.full-screen-loading-background {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    opacity: 1;
    z-index: 999;
}
